export enum TypeRoute {
  // eslint-disable-next-line no-unused-vars
  HALF_PM = 'Media ruta tarde',
  // eslint-disable-next-line no-unused-vars
  HALF_AM = 'Media ruta mañana',
  // eslint-disable-next-line no-unused-vars
  FULL = 'Ruta completa',
  // eslint-disable-next-line no-unused-vars
  SHARED = 'Ruta compartida',
}

export enum StatusRoute {
  // eslint-disable-next-line no-unused-vars
  requested = 'Solicitada',
  // eslint-disable-next-line no-unused-vars
  process = 'Gestión',
  // eslint-disable-next-line no-unused-vars
  approved = 'Aprobada',
  // eslint-disable-next-line no-unused-vars
  reject = 'Rechazada',
  // eslint-disable-next-line no-unused-vars
  cancelled = 'Cancelada',
  // eslint-disable-next-line no-unused-vars
  mapped = 'Asignada',
  // eslint-disable-next-line no-unused-vars
  signed = 'Firmada',
}

export interface IRouteRequest {
  name?: string;
  type: TypeRoute;
  school_id: string;
  start_date: string;
  school_name: string;
  route_request_id: number;
  route_parent_id?: number;
  route_name_id: number;
  route_student_id?: number;
  source_address_id?: number;
  source_address?: string;
  destination_address_id?: number;
  destination_address?: string;
  route_provider_id: number;
  status: StatusRoute;
  price: number;
  observation?: string;
  created_at: string;
  updated_at: string;
  owner_id: string;
  parent_id?: string;
  student_id?: string;
  verified: boolean;
  parent_name?: string;
  student_name?: string;
  parent_phone?: string;
  issigned?: boolean;
  id_hubspot_child?: string;
  studen_grade?: string;
  parent_email?: string;
  route_student_name?: string;
  route_parent_name?: string;
  is_cancellation?: boolean;
}
